<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
						<i class="feather icon-filter text-white"></i> Filter
					</button>

					<div class="btn-group" v-if="hasPermission('Product_Create')">
						<button
							class="btn btn-primary btn-rounded btn-space float-right"
							@click="viewAdd"
							v-if="
								distributorProductPermission &&
									distributorProductPermission.onlySellMyProducts
							"
						>
							<span class="icon icon-left mdi mdi-plus text-white"></span> New product
						</button>
						<button
							class="btn btn-primary btn-rounded btn-space float-right"
							@click="viewAddMyProduct"
							v-else-if="
								distributorProductPermission &&
									distributorProductPermission.onlySellTheirProducts
							"
						>
							<span class="icon icon-left mdi mdi-plus text-white"></span> New product
						</button>
						<button
							class="btn btn-primary btn-rounded btn-space float-right"
							@click="viewAddProductOptions"
							v-else-if="
								distributorProductPermission &&
									distributorProductPermission.noRestriction
							"
						>
							<span class="icon icon-left mdi mdi-plus text-white"></span> New product
						</button>
						<!-- <button
              class="btn btn-primary btn-rounded btn-space float-right"
              @click="viewUploadProducts"
              v-if="hasPermission('Product_Create')"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span>
              Upload Products
            </button> -->
					</div>
				</div>
				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table
									class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-striped table-hover-animation
                  "
								>
									<thead>
										<tr>
											<th style="width: 20%">Name</th>
											<th style="width: 10%">Status</th>
											<th style="width: 15%">Category</th>
											<th style="width: 15%">Inventory</th>
											<th style="width: 10%">Reorder Level</th>
											<th style="width: 15%">Created</th>
											<th style="width: 15%"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(p, index) in Products" :key="index">
											<td>
												<div class="d-flex align-items-center">
													<img
														v-if="p.imageUrl"
														class="image-preview mr-1"
														:src="p.imageUrl"
														alt="logo"
														width="40"
														height="40"
													/>
													<img
														v-else
														class="image-preview mr-1"
														src="/assets/img/avatar.svg"
														alt="logo"
														width="40"
													/>
													<div>
														{{ p.name }} ({{ p.code }})
														<br />
														<b>
															{{
																`&#8358;${formatMoney(
																	p.retailPrice,
																)}`
															}}
															/
															{{ p.unit }}
														</b>
													</div>
												</div>
											</td>
											<td>
												<span
													:class="[
														'badge',
														' badge-pill',
														{ 'badge-success': p.active },
														{ 'badge-danger': !p.active },
													]"
												>
													{{ p.active ? "Active" : "Inactive" }}
												</span>
											</td>
											<td>
												{{ p.category.name }} /
												{{ p.name }}
											</td>
											<td>
												{{ p.type == "Infinite" ? p.type : p.quantity }}
											</td>
											<td>{{ p.minimumStockLevel }}</td>
											<td>{{ formatDate(p.created) }}</td>
											<td>
												<button
													class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
													type="button"
													id="dropdownMenu2"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Actions
												</button>
												<div
													class="dropdown-menu px-1"
													aria-labelledby="dropdownMenu2"
												>
													<div
														v-if="
															hasPermission('Product_Update') &&
																p.parentId == user.parentId
														"
													>
														<a
															v-if="p.parentId == p.parentId"
															class="dropdown-item"
															href="#"
															@click.prevent="viewEditMyProduct(p)"
														>
															<i class="feather icon-edit"></i>
															&nbsp;Edit
														</a>
														<a
															v-else
															class="dropdown-item"
															href="#"
															@click.prevent="viewEdit(p)"
														>
															<i class="feather icon-edit"></i>
															&nbsp;Edit
														</a>
													</div>

													<a
														class="dropdown-item"
														v-if="hasPermission('Product_Delete')"
														href="#"
														style="color: red"
														@click.prevent="confirmDelete(p.id)"
													>
														Delete Product
													</a>
												</div>
											</td>
										</tr>
										<tr v-if="!Products.length">
											<td colspan="7">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-tag-multiple"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													You don't have any products.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
								<Pagination
									:limit="pageSize"
									:count="pageCount"
									:page="page"
									:itemsCount="count"
									@changePage="gotoPage"
									@changeLimit="changeLimit"
								/>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} product{{ count != 1 ? "s" : "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-add-product-options"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						<div class="text-center">
							<h3 class="p-1">Confirmation</h3>
							<p>How would like to create your product?</p>
						</div>
						<div class="form-group row">
							<div class="col-12 col-sm-12 col-lg-12">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="addProductOption"
								>
									<option value="Import">Import Enterprise Product</option>
									<option value="Create">Create a product</option>
								</select>
								<ul v-if="v5.addProductOption" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 140px"
							:disabled="isBusyModal"
							class="btn btn-warning"
							type="button"
							@click.prevent="addProductOptionPost"
						>
							<span>Proceed</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
			<div class="modal-dialog modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Create new product</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									:disabled="isBusy"
									v-model="createProduct.manufacturerProductId"
									@input="getPrice($event.target.value, false)"
								>
									<option
										v-for="p in manufacturerProducts"
										:key="p.id"
										:value="p.id"
									>
										{{ p.name }}
									</option>
								</select>
								<ul
									v-if="v1.manufacturerProductId"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Unit</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control']"
									type="text"
									v-model="manufacturerUnit"
									disabled
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Category</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="createProduct.categoryId"
								>
									<option
										v-for="cat in filteredCategory"
										:key="cat.id"
										:value="cat.id"
									>
										{{ cat.name }}
									</option>
								</select>
								<ul v-if="v3.categoryId" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Distributor Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control']"
									type="text"
									v-model="manufacturerPrice"
									disabled
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Retail Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.retailPrice }]"
									type="number"
									min="1"
									v-model="createProduct.retailPrice"
								/>
								<ul v-if="v1.retailPrice" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12 col-sm-10 col-lg-12">
									<input
										:class="['form-control', { 'is-invalid': v1.code }]"
										class="col-12"
										type="text"
										v-model="createProduct.code"
										@blur="
											validateProductCode(createProduct.code, user.parentId)
										"
									/>
									<ul v-if="v1.code" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
									<span
										v-if="isProductValid == true"
										class="text-danger error-text "
									>
										Product code already exists
									</span>
								</div>
								<i
									v-if="isProductValid == true"
									class="text-danger fa fa-times"
								></i>
								<i
									v-if="isProductValid == false"
									class="text-success fa fa-check"
								></i>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.name }]"
									type="text"
									v-model="createProduct.name"
								/>
								<ul v-if="v1.name" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									v-model="createProduct.description"
								></textarea>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Reorder Level</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.msl }]"
									type="number"
									v-model="createProduct.msl"
								/>
								<ul v-if="v1.msl" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Available for sale</label
							>
							<div class="col-12 col-sm-8 col-lg-8 d-flex align-items-center ">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createProduct.availability"
										false-value="0"
										true-value="1"
									/>
									<span class="custom-control-label"
										>This product is available for sale</span
									>
								</label>
								<ul v-if="v1.availability" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createProduct.active"
									/>
									<span class="custom-control-label">This product is active</span>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Allow Product Input in fractions</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createProduct.fractionalValue"
									/>
									<span class="custom-control-label"
										>This product allows fractional value</span
									>
								</label>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Image</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div class="d-flex align-items-center">
										<div>
											<img
												v-if="previewUrl"
												class="image-preview"
												:src="previewUrl"
												alt="logo"
												width="40"
												height="40"
											/>
										</div>

										<input
											id="file"
											type="file"
											class="custom-file-input"
											@change="uploadFile"
											ref="file"
											hidden
										/>
										<button
											v-if="previewUrl"
											class="btn btn-custom btn-outline-danger btn-nobordered btn-md ml-2"
											@click="cancelUpload"
										>
											Delete image
										</button>
										<button
											v-else
											class="btn btn-custom btn-outline-primary btn-md ml-2"
											@click="onPickFile"
										>
											Upload Image
										</button>
									</div>

									<small class="image-size-text">(Max 1MB)</small>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="resetProductForm"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="create"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Add product</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Edit product</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
							@click="cancel"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									disabled
									class="form-control"
									placeholder="Select an option"
									v-model="updateProduct.manufacturerProductId"
									@input="getPrice($event.target.value, true)"
								>
									<option
										v-for="p in manufacturerProducts"
										:key="p.id"
										:value="p.id"
									>
										{{ p.name }} ({{ p.unit }})
									</option>
								</select>
								<ul
									v-if="v2.manufacturerProductId"
									class="parsley-errors-list filled"
								>
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.code }]"
									type="text"
									v-model="updateProduct.code"
								/>
								<ul v-if="v2.code" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Distributor Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control']"
									type="text"
									v-model="manufacturerPrice"
									disabled
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Retail Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.retailPrice }]"
									type="number"
									v-model="updateProduct.retailPrice"
									:disabled="allowPriceOverride"
								/>
								<ul v-if="v2.retailPrice" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.name }]"
									type="text"
									v-model="updateProduct.name"
								/>
								<ul v-if="v2.name" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									v-model="updateProduct.description"
								></textarea>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Reorder Level</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.msl }]"
									type="number"
									v-model="updateProduct.minimumStockLevel"
								/>
								<ul v-if="v2.msl" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Available for sale</label
							>
							<div class="col-12 col-sm-8 col-lg-8 d-flex align-items-center ">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateProduct.availability"
										false-value="0"
										true-value="1"
									/>
									<span class="custom-control-label"
										>This product is available for sale</span
									>
								</label>
								<ul v-if="v1.availability" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateProduct.active"
									/>
									<span class="custom-control-label">This product is active</span>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Allow Product Input in fractions</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateProduct.fractionalValue"
									/>
									<span class="custom-control-label"
										>This product allows fractional value</span
									>
								</label>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Image</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div class="d-flex align-items-center">
										<div>
											<img
												v-if="previewUrl"
												class="image-preview"
												:src="previewUrl"
												alt="logo"
												width="40"
												height="40"
											/>
										</div>

										<input
											id="file"
											type="file"
											class="custom-file-input"
											@change="uploadFile"
											ref="file"
											hidden
										/>
										<button
											v-if="previewUrl"
											class="btn btn-custom btn-outline-danger btn-nobordered btn-md ml-2"
											@click="cancelUpload"
										>
											Delete image
										</button>
										<button
											v-else
											class="btn btn-custom btn-outline-primary btn-md ml-2"
											@click="onPickFile"
										>
											Upload Image
										</button>
									</div>

									<small class="image-size-text">(Max 1MB)</small>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="resetProductForm"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="update"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Update</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-add-my-product"
			role="dialog"
		>
			<div class="modal-dialog lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Create new product</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Category</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="createMyProduct.categoryId"
								>
									<option
										v-for="cat in filteredCategory"
										:key="cat.id"
										:value="cat.id"
									>
										{{ cat.name }}
									</option>
								</select>
								<ul v-if="v3.categoryId" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12 col-sm-10 col-lg-12">
									<input
										:class="['form-control', { 'is-invalid': v1.code }]"
										class="col-12"
										type="text"
										v-model="createMyProduct.code"
										@blur="
											validateProductCode(createMyProduct.code, user.parentId)
										"
									/>
									<ul v-if="v3.code" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
									<span
										v-if="isProductValid == true"
										class="text-danger error-text "
									>
										Product code already exists
									</span>
								</div>
								<i
									v-if="isProductValid == true"
									class="text-danger fa fa-times"
								></i>
								<i
									v-if="isProductValid == false"
									class="text-success fa fa-check"
								></i>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.name }]"
									type="text"
									v-model="createMyProduct.name"
								/>
								<ul v-if="v3.name" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									v-model="createMyProduct.description"
								></textarea>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Unit</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="createMyProduct.unit"
								>
									<option
										v-for="unit in units"
										:key="unit.name"
										:value="unit.name"
									>
										{{ unit.name }}
									</option>
								</select>
								<ul v-if="v3.unit" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Retail Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.retailPrice }]"
									type="number"
									v-model="createMyProduct.retailPrice"
								/>
								<ul v-if="v3.retailPrice" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Reorder Level</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="[
										'form-control',
										{ 'is-invalid': v3.minimumStockLevel },
									]"
									type="number"
									v-model="createMyProduct.minimumStockLevel"
								/>
								<ul v-if="v3.msl" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createMyProduct.active"
									/>
									<span class="custom-control-label">This product is active</span>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Allow Product Input in fractions</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createMyProduct.fractionalValue"
									/>
									<span class="custom-control-label"
										>This product allows fractional value</span
									>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Available for sale</label
							>
							<div class="col-12 col-sm-8 col-lg-8 d-flex align-items-center ">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="createMyProduct.availability"
										false-value="0"
										true-value="1"
									/>
									<span class="custom-control-label"
										>This product is available for sale</span
									>
								</label>
								<ul v-if="v3.availability" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Image</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div class="d-flex align-items-center">
										<div>
											<img
												v-if="previewUrl"
												class="image-preview"
												:src="previewUrl"
												alt="logo"
												width="40"
												height="40"
											/>
										</div>

										<input
											id="file"
											type="file"
											class="custom-file-input"
											@change="uploadFile"
											ref="file"
											hidden
										/>
										<button
											v-if="previewUrl"
											class="btn btn-custom btn-outline-danger btn-nobordered btn-md ml-2"
											@click="cancelUpload"
										>
											Delete image
										</button>
										<button
											v-else
											class="btn btn-custom btn-outline-primary btn-md ml-2"
											@click="onPickFile"
										>
											Upload Image
										</button>
									</div>

									<small class="image-size-text">(Max 1MB)</small>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="resetProductForm"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusy || isProductValid == true"
							class="btn btn-primary"
							type="button"
							@click.prevent="createMyProductPost"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Add product</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-edit-my-product"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Edit product</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
							@click="cancel"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body  modal-overflow">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Category</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="updateMyProduct.categoryId"
								>
									<option
										v-for="cat in filteredCategory"
										:key="cat.id"
										:value="cat.id"
									>
										{{ cat.name }}
									</option>
								</select>
								<ul v-if="v4.categoryId" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.code }]"
									type="text"
									v-model="updateMyProduct.code"
								/>
								<ul v-if="v4.code" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.name }]"
									type="text"
									v-model="updateMyProduct.name"
								/>
								<ul v-if="v4.name" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Description</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									class="form-control"
									v-model="updateMyProduct.description"
								></textarea>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right">Unit</label>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="updateMyProduct.unit"
								>
									<option
										v-for="unit in units"
										:key="unit.name"
										:value="unit.name"
									>
										{{ unit.name }}
									</option>
								</select>
								<ul v-if="v4.unit" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Retail Price</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v2.retailPrice }]"
									type="number"
									v-model="updateMyProduct.retailPrice"
								/>
								<ul v-if="v4.retailPrice" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Reorder Level</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="[
										'form-control',
										{ 'is-invalid': v4.minimumStockLevel },
									]"
									type="number"
									v-model="updateMyProduct.minimumStockLevel"
								/>
								<ul v-if="v4.msl" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a valid number.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateMyProduct.active"
									/>
									<span class="custom-control-label">This product is active</span>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Allow Product Input in fractions</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateMyProduct.fractionalValue"
									/>
									<span class="custom-control-label"
										>This product allows fractional value</span
									>
								</label>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Available for sale</label
							>
							<div class="col-12 col-sm-8 col-lg-8 d-flex align-items-center ">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="updateMyProduct.availability"
										false-value="0"
										true-value="1"
									/>
									<span class="custom-control-label"
										>This product is available for sale</span
									>
								</label>
								<ul v-if="v3.availability" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Image</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div class="d-flex align-items-center">
										<div>
											<img
												v-if="previewUrl"
												class="image-preview"
												:src="previewUrl"
												alt="logo"
												width="40"
												height="40"
											/>
										</div>

										<input
											id="file"
											type="file"
											class="custom-file-input"
											@change="uploadFile"
											ref="file"
											hidden
										/>
										<button
											v-if="previewUrl"
											class="btn btn-custom btn-outline-danger btn-nobordered btn-md ml-2"
											@click="cancelUpload"
										>
											Delete image
										</button>
										<button
											v-else
											class="btn btn-custom btn-outline-primary btn-md ml-2"
											@click="onPickFile"
										>
											Upload Image
										</button>
									</div>

									<small class="image-size-text">(Max 1MB)</small>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="resetProductForm"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							:disabled="isBusy"
							class="btn btn-primary"
							type="button"
							@click.prevent="updateMyProductPost"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Update</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Filter</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="select2 form-control"
									placeholder="Select an option"
									v-model="filter.status"
								>
									<option value="0">All Products</option>
									<option value="True">Active</option>
									<option value="False">Disabled</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Category</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.categoryId"
								>
									<option value="0">All Categories</option>
									<option v-for="cat in categories" :key="cat.id" :value="cat.id">
										{{ cat.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Other filters</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by code or name..."
									v-model="filter.qry"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getProducts"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
		<GeneralDeleteCaution
			:id="DeleteId"
			@proceed="deleteProduct"
			:isBusy="isBusyModal"
			text="product"
		/>
		<GeneralDeleteMessage
			message="You cannot delete this product because there is an order or inventory transaction history associated with it."
		/>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import { helper } from "@/helper";
	import Select2 from "@/components/Select2";
	import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
	import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
	import SystemPermissions from "@/permissions";
	import Pagination from "../components/Pagination.vue";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "settingsProducts",
		components: {
			Select2,
			GeneralDeleteCaution,
			GeneralDeleteMessage,
			Pagination,
		},

		created() {
			if (!this.hasPermission("Product_View")) {
				this.$router.push("/restricted");
			}
			this.getGroup();
			this.getProducts();
			this.getTypes();
			this.getCategories();
			this.getUnits();
			this.getDistributorProductPermission();

			this.originalProducts = this.Products;
		},

		watch: {
			"createProduct.type": function(newType, oldType) {
				if (newType == "Infinite") {
					this.createProduct.quantity = 0;
				}
			},

			"updateProduct.type": function(newType, oldType) {
				if (newType == "Infinite") {
					this.updateProduct.quantity = 0;
				}
			},
		},

		data() {
			return {
				groupId: "",
				filter: {
					status: "True",
					categoryId: "0",
					qry: "",
				},
				isBusy: false,
				isBusyModal: false,
				previewUrl: "",
				selectedproductValue: "",
				salesOptions: [
					{ key: 0, value: "Not for sale" },
					{ key: 1, value: "For sale" },
				],
				createProduct: {
					code: null,
					name: null,
					description: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: false,
					restockLevel: 0,
					availability: null,
					isDistributorsOwnProduct: null,
					unit: 0,
					categoryId: 0,
					fractionalValue: 0,
				},
				updateProduct: {
					code: null,
					name: null,
					description: null,
					availability: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: true,
					id: null,
					restockLevel: 0,
					manufacturerProductId: null,
					minimumStockLevel: 0,
					fractionalValue: 0,
				},
				editProduct: {
					code: null,
					name: null,
					description: null,
					volume: null,
					availability: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: true,
					id: null,
					restockLevel: 0,
					manufacturerProductId: null,
					msl: 0,
					fractionalValue: 0,
				},
				v1: {
					code: false,
					name: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					retailPrice: false,
					restockLevel: false,
					manufacturerProductId: false,
				},
				v2: {
					code: false,
					name: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					retailPrice: false,
					restockLevel: false,
					availability: false,
					manufacturerProductId: false,
					msl: 0,
				},
				createMyProduct: {
					code: null,
					name: null,
					description: null,
					availability: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					distributorPrice: null,
					active: false,
					categoryId: null,
					restockLevel: 0,
					minimumStockLevel: 0,
					allowPriceOverride: null,
					fractionalValue: 0,
				},
				updateMyProduct: {
					code: null,
					name: null,
					description: null,
					availability: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					distributorPrice: null,
					active: false,
					categoryId: null,
					id: null,
					restockLevel: 0,
					minimumStockLevel: 0,
					allowPriceOverride: null,
					fractionalValue: 0,
				},
				editMyProduct: {
					code: null,
					name: null,
					description: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					distributorPrice: null,
					active: true,
					categoryId: null,
					id: null,
					restockLevel: 0,
					availability: null,
					minimumStockLevel: 0,
					allowPriceOverride: null,
					fractionalValue: 0,
				},
				v3: {
					code: false,
					name: false,
					unit: false,
					group: false,
					groupSize: false,
					availability: false,
					quantity: false,
					type: false,
					retailPrice: false,
					distributorPrice: false,
					categoryId: false,
					restockLevel: false,
					minimumStockLevel: false,
				},
				v4: {
					code: false,
					name: false,
					unit: false,
					group: false,
					groupSize: false,
					availability: false,
					quantity: false,
					type: false,
					retailPrice: false,
					distributorPrice: false,
					categoryId: false,
					restockLevel: false,
					minimumStockLevel: false,
				},
				v5: {
					addProductOption: false,
				},
				Products: [],
				DeleteId: "",
				originalProducts: [],
				manufacturerProducts: [],
				types: [],
				categories: [],
				units: [],
				distributorProductPermission: {},
				addProductOption: null,
				manufacturerPrice: null,
				manufacturerCategory: null,
				manufacturerUnit: null,
				allowPriceOverride: true,
				count: 0,
				page: 0,
				pageNumber: 0,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				produck: null,
				isProductValid: null,
				images: null,
				selectedsalesOptions: null,
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},

			filteredCategory: function() {
				return this.categories.filter(x => x.active);
			},
			ProductCode() {
				return this.createMyProduct.code;
			},
		},

		watch: {
			// ProductCode(newValue, oldValue) {
			// 	if (newValue.length == 6) {
			// 		this.validateProductCode(newValue, this.user.parentId);
			// 	}
			// },
		},

		mounnted() {
			openModal();
		},

		methods: {
			onPickFile() {
				this.$refs.file.click();
			},
			cancelUpload() {
				this.previewUrl = "";
				this.images = null;
			},
			uploadFile() {
				this.images = this.$refs.file.files[0];
				this.previewUrl = URL.createObjectURL(this.images);
			},
			async validateProductCode(code, parentId) {
				this.isBusy = true;
				try {
					const response = await this.$http.get(
						`/Products/distributorCodeExist/${parentId}/${code}`,
					);
					if (response.ok) {
						this.isBusy = false;
						this.isProductValid = response.data;
						return;
					}
				} catch (err) {
					this.$toast.info(err, {
						icon: true,
						rtl: false,
					});
				}
			},
			async getGroup() {
				this.isBusy = true;
				try {
					const response = await this.$http.get(`/Groups/getGroup/`);
					if (response.ok) {
						this.groupId = response.body;
						this.getManufacturerProducts(this.groupId);
					}

					this.isBusy = true;
				} catch (error) {
					this.isBusy = false;
				}
			},

			confirmDelete(id) {
				this.DeleteId = id;
				$("#mod-confirm-delete").modal("show");
			},

			showDeleteMsg() {
				$("#mod-delete-msg").modal("show");
			},

			cancelConfirm() {
				this.DeleteId = "";
				$("#mod-confirm-delete").modal("hide");
			},
			async deleteProduct() {
				this.isBusyModal = true;
				try {
					const res = await this.$http.delete(`/Products/distributor/${this.DeleteId}`);
					if (res.ok) {
						this.$toast.success("Product deleted successfully", {
							icon: true,
							rtl: false,
						});
						this.isBusyModal = false;
						this.isBusy = false;
						this.getProducts();
						this.cancelConfirm();
					}
				} catch (error) {
					this.cancelConfirm();
					this.showDeleteMsg();
					this.isBusyModal = false;
					this.isBusy = false;
				}
			},

			async getPrice(id, isEdit) {
				this.isBusy = true;
				this.createProduct.manufacturerProductId = id;

				try {
					if (this.groupId) {
						const response = await this.$http.get(
							`/Groups/price/${this.groupId}/${id}`,
						);

						if (response.status === 200) {
							this.prepareProduct(id, response.body, isEdit);
						}
					} else {
						this.prepareProduct(id, { distributorPrice: 0, retailPrice: 0 }, isEdit);
					}
					this.isBusy = false;
				} catch (error) {
					this.isBusy = false;
				}
			},

			prepareProduct(id, price, isEdit) {
				const product = this.manufacturerProducts.find(pr => pr.id === id);

				this.manufacturerCategory = product.category.name;
				this.manufacturerUnit = product.unit;
				this.allowPriceOverride = !product.retailPrice === 0 || !product.allowPriceOverride;
				this.manufacturerPrice =
					price.distributorPrice > 0 ? price.distributorPrice : product.distributorPrice;
				if (isEdit) {
					this.updateProduct.retailPrice =
						price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
				} else {
					this.createProduct.retailPrice =
						price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
				}
			},

			reset() {
				this.filter = {
					status: "True",
					categoryId: "0",
					qry: "",
				};
			},

			cancel() {
				const currentProduct = this.Products.find(p => p.id === this.editProduct.id);
				this.allowPriceOverride = true;
				currentProduct.code = this.editProduct.code;
				currentProduct.name = this.editProduct.name;
				currentProduct.description = this.editProduct.description;
				currentProduct.volume = null;
				currentProduct.group = null;
				currentProduct.groupSize = null;
				currentProduct.quantity = this.editProduct.quantity;
				currentProduct.type = this.editProduct.type;
				currentProduct.retailPrice = this.editProduct.retailPrice;
				currentProduct.active = this.editProduct.active;
				currentProduct.id = this.editProduct.id;
				currentProduct.restockLevel = this.editProduct.restockLevel;
				currentProduct.manufacturerProductId = this.editProduct.manufacturerProductId;
				currentProduct.msl = this.editProduct.msl;
				$("#mod-edit").modal("hide");
			},

			viewExport() {
				//   $("#mod-export").modal("show");
			},

			viewAddProductOptions() {
				$("#mod-add-product-options").modal("show");
			},

			viewAdd() {
				this.getManufacturerProducts(this.groupId);
				this.manufacturerPrice = null;
				this.manufacturerCategory = null;
				this.manufacturerUnit = null;
				this.allowPriceOverride = null;
				this.createProduct = {
					code: null,
					name: null,
					description: null,
					unit: null,
					volume: null,
					availability: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: false,
					categoryId: null,
					restockLevel: 0,
					manufacturerProductId: null,
				};
				this.v1 = {
					code: false,
					name: false,
					unit: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					availability: null,
					categoryId: false,
					restockLevel: 0,
					manufacturerProductId: false,
					msl: 0,
				};
				$("#mod-add").modal("show");
			},

			viewUploadProducts() {
				this.$router.push("/products-upload");
			},

			viewEdit(c) {
				this.getManufacturerProducts();
				var editValues = {
					code: c.code,
					name: c.name,
					description: c.description,
					volume: null,
					group: null,
					groupSize: null,
					quantity: c.quantity,
					type: c.type,
					retailPrice: c.retailPrice,
					active: c.active,
					id: c.id,
					restockLevel: c.restockLevel,
					fractionalValue: c.allowDecimalQuantity,
					availability: c.saleStatus,
					selectedproductValue: c.saleStatus,
					manufacturerProductId: c.manufacturerProductId,
					msl: c.minimumStockLevel ? c.minimumStockLevel : 0,
				};
				this.editProduct = editValues;
				this.previewUrl = c.imageUrl;
				this.updateProduct = c;
				this.updateProduct.unit = c.unit;
				this.allowPriceOverride = !editValues.retailPrice === 0;
				this.manufacturerPrice = c.manufacturerProduct.distributorPrice;
				$("#mod-edit").modal("show");
			},

			viewAddMyProduct() {
				this.createMyProduct = {
					code: null,
					name: null,
					description: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					distributorPrice: null,
					active: false,
					categoryId: null,
					availability: null,
					restockLevel: 0,
					minimumStockLevel: 0,
					allowPriceOverride: false,
				};
				this.v3 = {
					code: false,
					name: false,
					unit: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					categoryId: false,
					availability: false,
					restockLevel: 0,
					minimumStockLevel: 0,
				};
				$("#mod-add-my-product").modal("show");
			},

			viewEditMyProduct(c) {
				console.log(c);
				var editValue = {
					code: c.code,
					name: c.name,
					description: c.description,
					quantity: c.quantity,
					type: c.type,
					retailPrice: c.retailPrice,
					distributorPrice: c.distributorPrice,
					unit: c.unit,
					categoryId: c.categoryId,
					active: c.active,
					restockLevel: c.restockLevel,
					minimumStockLevel: c.minimumStockLevel,
					allowPriceOverride: c.allowPriceOverride,
					availability: c.saleStatus,
					id: c.id,
					fractionalValue: c.allowDecimalQuantity,
				};
				this.editProduct = editValue;
				this.editMyProduct = editValue;
				this.previewUrl = c.imageUrl;
				this.updateMyProduct = c;
				this.updateMyProduct.categoryId = c.categoryId;
				this.updateMyProduct.unit = c.unit;
				this.updateMyProduct.manufacturerProductId = c.manufacturerProductId;
				this.updateMyProduct.availability = c.saleStatus;
				this.updateMyProduct.fractionalValue = c.allowDecimalQuantity;
				this.selectedsalesOptions = this.salesOptions.filter(
					x => x.key == this.updateMyProduct.availability,
				)[0].value;
				this.salesOptions.filter(x => {
					// console.log(x, this.updateMyProduct.availability);
				});
				// console.log(this.selectedsalesOptions, this.updateMyProduct.availability);
				$("#mod-edit-my-product").modal("show");
			},

			viewFilter() {
				this.pageNumber = 0;
				$("#mod-filter").modal("show");
			},

			addProductOptionPost() {
				if (!this.addProductOption || this.addProductOption == "") {
					this.v5.addProductOption = true;
					return;
				} else if (this.addProductOption == "Import") {
					$("#mod-add").modal("show");
				} else if (this.addProductOption == "Create") {
					$("#mod-add-my-product").modal("show");
				}
				$("#mod-add-product-options").modal("hide");
			},

			async create() {
				this.isBusyModal = true;
				this.isBusy = true;
				const formData = new FormData();
				formData.append("code", this.createProduct.code);
				formData.append("name", this.createProduct.name);
				formData.append("description", this.createProduct.description);
				formData.append("volume", "N/A");
				formData.append("group", "N/A");
				formData.append("groupSize", 0);
				formData.append("quantity", 9999999);
				formData.append("type", "Finite");
				formData.append("retailPrice", this.createProduct.retailPrice);
				formData.append("active", this.createProduct.active);
				formData.append("parentId", this.user.parentId);
				formData.append("restockLevel", this.createProduct.restockLevel);
				formData.append("SaleStatus", this.createProduct.availability);
				formData.append("manufacturerProductId", this.createProduct.manufacturerProductId);
				formData.append("categoryId", this.createProduct.categoryId);
				formData.append("msl", this.createProduct.msl);
				formData.append("AllowDecimalQuantity", this.createProduct.fractionalValue);
				formData.append("image", this.images);
				if (this.validateCreate()) {
					await this.$http
						.post("/Products/createForDistributor", formData)
						.then(function(res) {
							if (res.ok) {
								this.$toast.success("Product created successfuly", {
									icon: false,
									rtl: false,
								});
								this.isBusy = false;
								this.isBusyModal = false;
								$("#mod-add").modal("hide");
								this.cancelUpload();
								this.getProducts();
							}
							this.isBusyModal = false;
							this.addProductOption = null;
							this.createProduct.code = null;
							this.createProduct.name = null;
							this.createProduct.description = null;
							this.createProduct.volume = null;
							this.createProduct.group = null;
							this.createProduct.groupSize = null;
							this.createProduct.quantity = 99999999;
							this.createProduct.type = "Finite";
							this.createProduct.retailPrice = null;
							this.createProduct.active = false;
							this.createProduct.restockLevel = 0;
							this.createProduct.availability = 0;
							this.createProduct.manufacturerProductId = null;
							this.createProduct.msl = 0;
						})
						.catch(function() {
							this.isBusyModal = false;
							this.addProductOption = null;
							this.createProduct.code = null;
							this.createProduct.name = null;
							this.createProduct.description = null;
							this.createProduct.volume = null;
							this.createProduct.group = null;
							this.createProduct.groupSize = null;
							this.createProduct.quantity = 99999999;
							this.createProduct.type = "Finite";
							this.createProduct.retailPrice = null;
							this.createProduct.active = false;
							this.createProduct.restockLevel = 0;
							this.createProduct.availability = 0;
							this.createProduct.manufacturerProductId = null;
							this.createProduct.msl = 0;
							this.isBusy = false;
							this.cancelUpload();
						});
				}
			},

			validateCreate() {
				this.v1 = {
					code: false,
					name: false,
					quantity: false,
					type: false,
					restockLevel: false,
					retailPrice: false,
					manufacturerProductId: false,
					availability: false,
				};
				var isValid = true;

				if (!this.createProduct.code) {
					this.v1.code = true;
					isValid = false;
				}

				if (!this.createProduct.name) {
					this.v1.name = true;
					isValid = false;
				}

				if (!this.createProduct.manufacturerProductId) {
					this.v1.manufacturerProductId = true;
					isValid = false;
				}
				if (!this.createProduct.availability) {
					this.v1.availability = true;
					isValid = false;
				}

				if (Number(this.createProduct.retailPrice) <= 0) {
					this.v1.retailPrice = true;
					isValid = false;
				}

				if (Number(this.createProduct.restockLevel) < 0) {
					this.v1.restockLevel = true;
					isValid = false;
				}

				return isValid;
			},

			async update() {
				this.isBusyModal = true;
				this.isBusy = true;
				const formData = new FormData();
				formData.append("code", this.updateProduct.code);
				formData.append("name", this.updateProduct.name);
				formData.append("description", this.updateProduct.description);
				formData.append("volume", this.updateProduct.volume);
				formData.append("group", this.updateProduct.group);
				formData.append("groupSize", this.updateProduct.groupSize);
				formData.append("quantity", 9999999);
				formData.append("type", "Finite");
				formData.append("retailPrice", this.updateProduct.retailPrice);
				formData.append("active", this.updateProduct.active);
				formData.append("id", this.updateProduct.id);
				formData.append("restockLevel", this.updateProduct.restockLevel);
				formData.append("SaleStatus", this.updateProduct.availability);
				formData.append("manufacturerProductId", this.updateProduct.manufacturerProductId);
				formData.append("msl", this.updateProduct.minimumStockLevel);
				formData.append("AllowDecimalQuantity", this.updateProduct.fractionalValue);
				formData.append("image", this.this.updateProduct.images);

				if (this.validateUpdate()) {
					$("#mod-edit").modal("hide");

					await this.$http
						.put("/Products/updateForDistributor/" + this.updateProduct.id, formData)
						.then(function(res) {
							this.isBusyModal = false;
							this.isBusy = false;
							if (this.$route.params.id) this.$router.push("/products");
							this.cancelUpload();
							this.getProducts();
						})
						.catch(function() {
							this.isBusyModal = false;
							this.isBusy = false;
						});

					this.getProducts();
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			validateUpdate() {
				this.v2 = {
					code: false,
					name: false,
					quantity: false,
					type: false,
					retailPrice: false,
					restockLevel: false,
					manufacturerProductId: false,
				};
				var isValid = true;

				if (!this.updateProduct.code) {
					this.v2.code = true;
					isValid = false;
				}

				if (!this.updateProduct.name) {
					this.v2.name = true;
					isValid = false;
				}

				if (!this.updateProduct.manufacturerProductId) {
					this.v2.manufacturerProductId = true;
					isValid = false;
				}

				if (Number(this.updateProduct.retailPrice) <= 0) {
					this.v2.retailPrice = true;
					isValid = false;
				}

				if (Number(this.updateProduct.restockLevel) < 0) {
					this.v2.restockLevel = true;
					isValid = false;
				}

				return isValid;
			},

			async createMyProductPost() {
				this.isBusy = true;
				const formData = new FormData();
				formData.append("code", this.createMyProduct.code);
				formData.append("name", this.createMyProduct.name);
				formData.append("description", this.createMyProduct.description);
				formData.append("unit", this.createMyProduct.unit);
				formData.append("volume", "N/A");
				formData.append("group", "N/A");
				formData.append("groupSize", 0);
				formData.append("quantity", 9999999);
				formData.append("type", "Finite");
				formData.append("categoryId", this.createMyProduct.categoryId);
				formData.append("retailPrice", this.createMyProduct.retailPrice);
				formData.append("distributorPrice", this.createMyProduct.distributorPrice);
				formData.append("active", this.createMyProduct.active);
				formData.append("parentId", this.user.parentId);
				formData.append("restockLevel", this.createMyProduct.restockLevel);
				formData.append("SaleStatus", this.createMyProduct.availability);
				formData.append("msl", this.createMyProduct.minimumStockLevel);
				formData.append("allowPriceOverride", this.createMyProduct.allowPriceOverride);
				formData.append("isDistributorsOwnProduct", true);
				formData.append("AllowDecimalQuantity", this.createMyProduct.fractionalValue);
				formData.append("image", this.images);

				if (this.validateCreateMyProduct()) {
					await this.$http
						.post("/Products/createForDistributor", formData)
						.then(function(res) {
							if (res.ok) {
								this.$toast.success("Product created successfuly", {
									icon: false,
									rtl: false,
								});
								this.isBusyModal = false;
								this.isBusy = false;
								$("#mod-add-my-product").modal("hide");
								this.cancelUpload();
								this.getProducts();
							}
							this.addProductOption = null;
							this.createMyProduct.code = null;
							this.createMyProduct.unit = null;
							this.createMyProduct.name = null;
							this.createMyProduct.description = null;
							this.createMyProduct.volume = null;
							this.createMyProduct.group = null;
							this.createMyProduct.groupSize = null;
							this.createMyProduct.quantity = 99999999;
							this.createMyProduct.type = "Finite";
							this.createMyProduct.retailPrice = null;
							this.createMyProduct.active = false;
							this.createMyProduct.restockLevel = 0;
							this.createMyProduct.categoryId = "";
							this.createMyProduct.minimumStockLevel = 0;
							this.createMyProduct.manufacturerProductId = null;
							this.createMyProduct.msl = 0;
						})
						.catch(function() {
							this.isBusyModal = false;
							this.isBusy = false;
							$("#mod-add-my-product").modal("hide");
							this.addProductOption = null;
							this.createMyProduct.code = null;
							this.createMyProduct.unit = null;
							this.createMyProduct.name = null;
							this.createMyProduct.description = null;
							this.createMyProduct.volume = null;
							this.createMyProduct.group = null;
							this.createMyProduct.groupSize = null;
							this.createMyProduct.quantity = 99999999;
							this.createMyProduct.type = "Finite";
							this.createMyProduct.retailPrice = null;
							this.createMyProduct.active = false;
							this.createMyProduct.restockLevel = 0;
							this.createMyProduct.categoryId = "";
							this.createMyProduct.minimumStockLevel = 0;
							this.createMyProduct.manufacturerProductId = null;
							this.createMyProduct.msl = 0;
							this.cancelUpload();
						});
				}
			},

			validateCreateMyProduct() {
				this.v1 = {
					code: false,
					name: false,
					unit: false,
					quantity: false,
					type: false,
					categoryId: false,
					restockLevel: false,
					minimumStockLevel: false,
				};
				var isValid = true;

				if (!this.createMyProduct.code) {
					this.v3.code = true;
					isValid = false;
				}

				if (!this.createMyProduct.name) {
					this.v3.name = true;
					isValid = false;
				}

				if (!this.createMyProduct.unit) {
					this.v3.unit = true;
					isValid = false;
				}

				if (!this.createMyProduct.categoryId) {
					this.v3.categoryId = true;
					isValid = false;
				}

				if (Number(this.createMyProduct.retailPrice) <= 0) {
					this.v3.retailPrice = true;
					isValid = false;
				}

				//if (Number(this.createMyProduct.distributorPrice) <= 0) {
				//    this.v3.distributorPrice = true;
				//    isValid = false;
				//}

				if (Number(this.createMyProduct.minimumStockLevel) < 0) {
					this.v3.minimumStockLevel = true;
					isValid = false;
				}

				return isValid;
			},

			async updateMyProductPost() {
				this.isBusyModal = true;
				this.isBusy = true;
				const formData = new FormData();
				formData.append("code", this.updateMyProduct.code);
				formData.append("name", this.updateMyProduct.name);
				formData.append("description", this.updateMyProduct.description);
				formData.append("unit", this.updateMyProduct.unit);
				formData.append("volume", this.updateMyProduct.volume);
				formData.append("group", this.updateMyProduct.group);
				formData.append("groupSize", this.updateMyProduct.groupSize);
				formData.append("quantity", 9999999);
				formData.append("type", "Finite");
				formData.append("retailPrice", this.updateMyProduct.retailPrice);
				formData.append("categoryId", this.updateMyProduct.categoryId);
				formData.append("active", this.updateMyProduct.active);
				formData.append("id", this.updateMyProduct.id);
				formData.append("restockLevel", this.updateMyProduct.restockLevel);
				formData.append("SaleStatus", this.updateMyProduct.availability);
				formData.append("AllowDecimalQuantity", this.updateMyProduct.fractionalValue);
				formData.append(
					"manufacturerProductId",
					this.updateMyProduct.manufacturerProductId,
				);
				formData.append("msl", this.updateMyProduct.minimumStockLevel);
				formData.append("image", this.images);

				if (this.validateUpdateMyProduct()) {
					await this.$http
						.put("/Products/updateForDistributor/" + this.updateMyProduct.id, formData)
						.then(function(res) {
							this.isBusy = false;
							$("#mod-edit-my-product").modal("hide");
							this.isBusyModal = false;
							this.isBusy = false;
							this.getProducts();
							this.cancelUpload();
						})
						.catch(function(err) {
							connsole.log(err);
							this.isBusy = false;
							this.isBusyModal = false;
						});
				}
			},
			validateUpdateMyProduct() {
				this.v4 = {
					code: false,
					name: false,
					unit: false,
					// group: false,
					// groupSize: false,
					quantity: false,
					type: false,
					categoryId: false,
					restockLevel: false,
					minimumStockLevel: false,
				};
				var isValid = true;

				if (!this.updateMyProduct.code) {
					this.v4.code = true;
					isValid = false;
					this.isBusyModal = false;
					this.isBusy = false;
				}

				if (!this.updateMyProduct.name) {
					this.v4.name = true;
					isValid = false;
					this.isBusyModal = false;
					this.isBusy = false;
				}

				if (!this.updateMyProduct.unit) {
					this.v4.unit = true;
					isValid = false;
					this.isBusyModal = false;
					this.isBusy = false;
				}

				if (!this.updateMyProduct.categoryId) {
					this.v4.categoryId = true;
					isValid = false;
					this.isBusyModal = false;
					this.isBusy = false;
				}

				if (Number(this.updateMyProduct.retailPrice) <= 0) {
					this.v4.retailPrice = true;
					isValid = false;
					this.isBusyModal = false;
					this.isBusy = false;
				}

				//if (Number(this.updateMyProduct.distributorPrice) <= 0) {
				//    this.v4.distributorPrice = true;
				// //    isValid = false;
				// this.isBusyModal = false;
				// 			this.isBusy = false;
				//}

				if (Number(this.updateMyProduct.minimumStockLevel) < 0) {
					this.v4.minimumStockLevel = true;
					isValid = false;
					this.isBusyModal = false;
					this.isBusy = false;
				}

				return isValid;
			},

			async getProducts() {
				this.isBusy = true;
				await this.$http
					.get(
						"/Products/getListForDistributor?pageNumber=" +
							this.pageNumber +
							"&pageSize=" +
							this.pageSize +
							(this.filter.status != "0" ? "&active=" + this.filter.status : "") +
							(this.filter.categoryId != "0"
								? "&categoryId=" + this.filter.categoryId
								: "") +
							(this.filter.qry != "" ? "&query=" + this.filter.qry : ""),
					)
					.then(function(res) {
						this.Products = res.body.data;
						this.pageNumber = res.body.pageNumber;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			async getManufacturerProducts(group) {
				this.isBusy = true;
				await this.$http
					.get(
						"/Products/getListNoPagingForManufacturer?" +
							(this.user.parentId ? "parentId=" + this.user.parentId : "") +
							"&active=true" +
							(group ? "&groupId=" + group : ""),
					)
					.then(function(res) {
						this.manufacturerProducts = res.body;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			getTypes() {
				this.types = ["Finite", "Infinite"];
			},

			getCategories() {
				var t = "Product Category";

				this.$http
					.get(
						"/Categories/getListNoPaggingForDistributor?type=" +
							t +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.categories = res.body;
					})
					.catch(function() {});
			},
			getUnits() {
				var t = "Product Unit";
				var a = true;

				this.$http
					.get(
						"/Categories/getListNoPaggingForDistributor?type=" +
							t +
							"&active=" +
							a +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.units = res.body;
					})
					.catch(function() {});
			},
			async getDistributorProductPermission() {
				this.isBusy = true;

				await this.$http
					.get("/Accounts/getDistributorProductPermission/" + this.user.parentId)
					.then(function(res) {
						this.distributorProductPermission = res.body;
						this.isBusy = false;
					})
					.catch(function() {});

				this.isBusy = false;
			},
			formatDate(date) {
				return moment(date).format("lll");
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			formatNumber(val) {
				return helper.formatNumber(val);
			},
			prev() {
				this.pageNumber -= 1;
				this.getProducts();
			},
			next() {
				this.pageNumber += 1;
				this.getProducts();
			},

			gotoPage(page) {
				this.pageNumber = page;
				this.getProducts();
			},

			changeLimit(limit) {
				this.pageSize = limit;
				this.getProducts();
			},
			resetProductForm(){
				let formReset = {
					code: null,
					name: null,
					description: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: false,
					restockLevel: 0,
					availability: null,
					isDistributorsOwnProduct: null,
					unit: 0,
					categoryId: 0,
					fractionalValue: 0,
				};
				this.createProduct = formReset;
				this.updateProduct = formReset;
				this.updateMyProduct = formReset;
				this.createMyProduct = formReset;
				this.isProductValid = null;
				this.addProductOption= null;
				this.previewUrl = '';
			}
		},
	};
</script>

<style scoped>
	.error-text {
		font-size: 8px;
	}
	.modal-body {
		max-height: 500px;
		overflow-y: auto;
	}
	.lg-modal {
		max-width: 600px !important;
		width: 100%;
	}
	.custom-file-input {
		color: transparent;
		opacity: 1 !important;
	}
	.custom-file-input::-webkit-file-upload-button {
		visibility: hidden;
	}
	.custom-file-input::before {
		content: "Upload Image";
		color: #7367f0;
		display: inline-block;
		background-color: #ffffff !important;
		border: 1px solid #7367f0;
		border-radius: 5px;
		padding: 4px 14px;
		outline: none;
		white-space: nowrap;
		-webkit-user-select: none;
		cursor: pointer;
		font-weight: 300;
		font-size: 10pt;
	}
	.custom-file-input:hover::before {
		border-color: #7367f0;
	}
	.custom-file-input:active {
		outline: 0;
	}
	.custom-file-input:active::before {
		background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
	}
	.image-size-text {
		color: Gray;
		font-size: 10px;
		font-weight: 200;
		height: auto !important;
	}
	.image-preview {
		border-radius: 50%;
	}
	.btn-custom {
		padding: 8px 18px !important;
	}
	.btn-nobordered {
		border: none;
	}
</style>
