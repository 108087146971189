<template>
	<div class="row">
		<div v-if="!returnDetails" class="col-lg-12">
			<div v-if="!returnDetailsiisActive" class="col-lg-12">
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						' card-table',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header">
						<button
							v-if="hasPermission('Product_Category_Create')"
							class="btn btn-primary btn-rounded btn-space ml-auto"
							@click="initializeCustomerReturns"
						>
							<i class="feather icon-plus text-white"></i> &nbsp;Initiate Returns
						</button>
					</div>

					<div class="card-body">
						<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
							<div class="row be-datatable-body">
								<div class="col-sm-12 table-responsive">
									<table
										class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-hover-animation table-striped
                  "
									>
										<thead>
											<tr>
												<th>Return Id</th>
												<th>Customers Name</th>
												<th>Return Date</th>
												<th>Created by</th>
												<th>Entry Date</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="cr in customerReturnList" :key="cr.id">
												<td>{{ cr.returnId }}</td>
												<td>{{ cr.customer }}</td>
												<td>{{ formatDate(cr.returnDate) }}</td>
												<td>{{ cr.createdBy }}</td>
												<td>{{ formatDate(cr.entryDate) }}</td>
												<td>
													<button
														class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
														type="button"
														id="dropdownMenu2"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														Actions
													</button>
													<div
														class="dropdown-menu px-1"
														aria-labelledby="dropdownMenu2"
													>
														<div>
															<a
																class="dropdown-item"
																href="#"
																@click.prevent="
																	viewDetails(cr.id, cr.returnId)
																"
															>
																<i class="feather icon-eye"></i>
																&nbsp;View Details
															</a>
														</div>
													</div>
												</td>
											</tr>
											<tr v-if="customerReturnList.length == 0">
												<td colspan="7">
													<div
														class="text-center"
														style="padding-top: 50px"
													>
														<span
															style="font-size: 4.615rem"
															class="mdi mdi-tag-multiple"
														></span>
													</div>
													<h3
														class="text-center"
														style="padding-bottom: 100px"
													>
														You don't have any customer returns.
													</h3>
												</td>
											</tr>
										</tbody>
									</table>
									<Pagination
										:limit="pageSize"
										:count="pageCount"
										:page="pageNumber"
										:itemsCount="count"
										@changePage="gotoPage"
										@changeLimit="changeLimit"
									/>
								</div>
							</div>
							<div class="row be-datatable-footer">
								<div class="col-sm-5">
									<div class="dataTables_info">
										{{ count }} Return Detail{{ count != 1 ? "s" : "" }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="be-spinner">
						<svg width="40px" height="40px" viewBox="0 0 66 66">
							<circle
								class="circle"
								fill="none"
								stroke-width="4"
								stroke-linecap="round"
								cx="33"
								cy="33"
								r="30"
							></circle>
						</svg>
					</div>
				</div>
			</div>
			<div v-if="returnDetailsiisActive" class="col-lg-12">
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						' card-table',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-header">
						<h4 class="modal-title px-1 pt-1">
							<strong>Initiate Returns</strong>
						</h4>

						<div class="btn-group">
							<h4 class="modal-title px-1 pt-1">
								<strong>Returns ID - 2112140663</strong>
							</h4>
						</div>
					</div>

					<div class="card-body">
						<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
							<div class="row be-datatable-body">
								<div class="col-sm-12 table-responsive">
									<table
										class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-hover-animation table-striped
                  "
									>
										<thead>
											<tr>
												<th>Product Name (Code)</th>
												<th>Quantity Returned</th>
												<th>Total Sale Amount</th>
												<th>Discount</th>
												<th>Order Number</th>
												<th>Action</th>
												<th>Location</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(oR, index) in returnItemDetails"
												:key="index"
											>
												<td>
													<div>
														{{ oR.productName }}
														<br />
														{{ oR.productCode }}
													</div>
												</td>
												<!-- <td>-</td> -->
												<td>{{ oR.productQuntityReturned }}</td>
												<td>{{ oR.productAmount }}</td>
												<td>{{ oR.productDiscount }}</td>
												<td>{{ oR.productOrderNumber }}</td>
												<td>
													{{ oR.productAction }}
												</td>
												--
												<td>
													{{ oR.productLocation }},
													{{ oR.productwarehouseLocation }}
												</td>
												<td>
													<button
														class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
														type="button"
														id="dropdownMenu2"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														Actions
													</button>
													<div
														style="width:300px"
														class="dropdown-menu px-1"
														aria-labelledby="dropdownMenu2"
													>
														<div>
															<a
																class="dropdown-item"
																href="#"
																@click.prevent="
																	returnAnotherProductFromThisOrder(
																		oR.productOrderNumber,
																	)
																"
															>
																&nbsp;Return another product from
																this order
															</a>
														</div>
														<div>
															<a
																class="dropdown-item"
																href="#"
																@click.prevent="
																	editReturnsData(
																		oR.productOrderNumber,
																	)
																"
															>
																&nbsp;Edit Record
															</a>
														</div>
														<div>
															<a
																class="dropdown-item text-danger"
																href="#"
																@click.prevent="
																	deleteReturns(
																		oR.productOrderNumber,
																	)
																"
															>
																&nbsp;Delete Record
															</a>
														</div>
													</div>
												</td>
											</tr>
											<tr v-if="returnItemDetails.length == 0">
												<td colspan="9">
													<div
														class="text-center"
														style="padding-top: 50px"
													>
														<span
															style="font-size: 4.615rem"
															class="mdi mdi-tag-multiple"
														></span>
													</div>
													<h3
														class="text-center"
														style="padding-bottom: 100px"
													>
														You don't have any customer returns.
													</h3>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="float-right">
					<div>
						<div class="d-flex justify-content-between ">
							<button
								class="btn btn-outline-danger btn-no-border btn-rounded btn-space float-right mr-2"
								@click="cancelCustomerReturns"
							>
								Cancel
							</button>
							<button
								class="btn btn-primary btn-rounded btn-space float-right"
								@click="completeReturns"
							>
								Complete Return
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="returnDetails" class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button class="btn btn-primary btn-rounded btn-space" @click="back">
						<i class="feather icon-arrow-left text-white"></i> &nbsp;Back
					</button>

					<div class="btn-group">
						<h4 class="modal-title px-1 pt-1">
							<strong>Returns ID - {{ returnID }}</strong>
						</h4>
					</div>
				</div>

				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table
									class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-hover-animation table-striped
                  "
								>
									<thead>
										<tr>
											<th>Product Name (Code)</th>
											<!-- <th>Product Unit</th> -->
											<th>Quantity Returned</th>
											<th>Total Sale Amount</th>
											<!-- <th>Discount</th> -->
											<th>Order Number</th>
											<th>Action</th>
											<th>Location</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(RD, index) in returnListDetails" :key="index">
											<td>
												<div>
													{{ RD.description }}
													<br />
													{{ RD.code }}
												</div>
											</td>
											<!-- <td>-</td> -->
											<td>{{ RD.quantity }}</td>
											<td>{{ RD.quantity * RD.unitPrice }}</td>
											<!-- <td>{{ RD.productDiscount }}</td> -->
											<td>{{ RD.orderNumber }}</td>
											<td>
												<span :class="[ 
	{ 'text-warning': RD.action == 0 },
	{ 'text-success': RD.action == 1 },
												{ 'text-danger': RD.action == 2 }]">
												{{
													RD.action == 2
														? "Reversed"
														: actionsList
																.filter(x => x.id == RD.action)
																.map(x => x.label)[0]
												}}
											</span>
											</td>
											<td>{{ RD.storageArea }},{{ RD.wareHouse }}</td>
											<td v-if="RD.action == 0 || RD.action == 1">
												<button
													class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
													type="button"
													id="dropdownMenu2"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Actions
												</button>
												<div
													class="dropdown-menu px-1"
													aria-labelledby="dropdownMenu2"
												>
													<div>
														<a
															class="dropdown-item"
															href="#"
															@click.prevent="ReverseModal(RD.id, 'Reverse')"
														>
															<i class="feather icon-arrow-left"></i>
															&nbsp;Reverse
														</a>
													</div>
												</div>
											</td>
											<td v-else>-</td>
										</tr>
										<tr v-if="returnListDetails.length == 0">
											<td colspan="9">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-tag-multiple"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													You don't have any customer returns.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-add-customer-returns"
			role="dialog"
		>
			<div class="modal-dialog  modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div>
							<h4 class="modal-title px-1 pt-1">
								<strong>Initiate Returns</strong>
							</h4>
						</div>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row my-1">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Select Customer</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<select2 placeholder="Select a customer" v-model="customerID">
									<option selected disabled>Select a customer</option>
									<option
										v-for="customer in customersOptions"
										:key="customer.key"
										:value="customer.key"
									>
										{{ customer.value }}
									</option>
								</select2>
								<ul v-if="v1.seletedCustomer" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="initializeCustomerReturnsSearch"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Done</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-add-customer-returns-search"
			role="dialog"
		>
			<div class="modal-dialog  modal-dialog-centered lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div>
							<h4 class="modal-title px-1 pt-1">
								<strong>Initiate Returns</strong>
							</h4>
							<small class="px-1">You can search by filling any of the fields</small>
						</div>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Search Order number</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									class="form-control"
									placeholder="Enter Order Number"
									type="text"
									v-model.number="orderNumber"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Select Date</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									class="form-control"
									placeholder="Select Date"
									type="date"
									:max="maxDate"
									v-model="date"
								/>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Search Product Name</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									class="form-control"
									type="text"
									placeholder="Enter Product Name"
									v-model.number="productName"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="getMerchantFulfilledOrders"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Search</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="modi-add-customer-returns-items"
			role="dialog"
		>
			<div class="modal-dialog  modal-dialog-scrollable lg-modal ">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div>
							<h4 class="modal-title px-1 pt-1">
								<strong>Initiate Returns</strong>
							</h4>
							<small class="px-1">You can search by filling any of the fields</small>
						</div>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body px-2">
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Order List</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<select
									class="form-control"
									v-model="returns.orderListNumber"
									@change="getProductList($event.target.value)"
								>
									<option disabled selected value>Select Order</option>
									<option
										v-for="o in orderList"
										:key="o.id"
										:value="o.orderNumber"
									>
										{{ o.orderNumber }}
									</option>
								</select>
								<ul v-if="v1.orderListNumber" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Select Product</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<select
									class="form-control"
									placeholder="Select an option"
									:disabled="isBusy"
									v-model="returns.product"
									@change="getProductDetailsList($event.target.value)"
								>
									<option disabled selected value>Select Product</option>
									<option v-for="p in productDetails" :key="p.id" :value="p.id">
										{{ p.description }}
									</option>
								</select>
								<ul v-if="v1.product" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Product Quantity</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									class="form-control"
									type="number"
									disabled
									placeholder="Enter Product Quantity"
									v-model.number="returns.productQuantity"
								/>
								<ul v-if="v1.productQuantity" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Enter quantity you wish to return</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<input
									class="form-control"
									type="number"
									placeholder="Enter Product desired Quantity"
									v-model.number="returns.desiredQuantity"
								/>
								<ul v-if="v1.desiredQuantity" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Return Action</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="returns.action"
								>
									<option
										v-for="acL in actionsList"
										:key="acL.id"
										:value="acL.id"
										>{{ acL.label }}</option
									>
								</select>
								<ul v-if="v1.action" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Warehouse</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="returns.locationId"
								>
									<option v-for="loc in Locations" :key="loc.id" :value="loc.id">
										{{ loc.name }}
									</option>
								</select>
								<ul v-if="v1.locationId" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Storage Area</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="returns.subLocationId"
								>
									<option
										v-for="loc in SubLocations"
										:key="loc.id"
										:value="loc.id"
									>
										{{ loc.name }}
									</option>
								</select>
								<ul v-if="v1.subLocationId" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row my-2">
							<label class="col-12 col-sm-4 col-form-label text-sm-right"
								>Reason</label
							>
							<div class="col-10 col-sm-8 col-lg-7">
								<v-select
									id="roleSelector"
									class="style-chooser"
									multiple
									:options="reasonList"
									:reduce="value => value.id"
									@input="isSelectReasons($event)"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="clearInput"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click="updateAddTobucket"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>{{ bucketEdiitMode ? "Update Returns" : "Add" }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-add-customer-returns-items"
			role="dialog"
		>
			<div class="modal-dialog  modal-dialog-centered sm-modal">
				<div class="modal-content p-1">
					<div class="modal-body text-center">
						<p class="text-center">Are you sure you want to return this stock?</p>
						<div class="d-flex justify-content-between">
							<button
								class="btn btn-outline-seconndary btn-no-border text-danger px-1"
								type="button"
								@click.prevent="dontAddReturnsTobucket"
							>
								No, I don't
							</button>
							<button
								:disabled="isBusyModal"
								class="btn btn-outline-dark btn-no-border px-1"
								type="button"
								@click.prevent="addReturnsTobucket"
							>
								<span v-if="isBusyModal">Busy...</span>
								<span v-else>Yes, I do</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-successfull-returns"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1">
							<strong>Customer Returns</strong>
						</h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
							@click="cancel"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<p class="text-center font-bold">
							Your have successfully completed <br />
							customer returns
						</p>
					</div>
					<div class="modal-footer">
						<button class="btn btn-primary" type="button">
							<span> Done </span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- Connfirmation Modal -->
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-action-res"
			role="dialog"
		>
			<div class="modal-dialog  modal-dialog-centered sm-modal">
				<div class="modal-content p-1">
					<div class="modal-body text-center">
						<p class="text-center">Are you sure you want to <b>{{action}}</b> this transaction?</p>
						<div class="d-flex justify-content-between">
							<button
								class="btn btn-outline-seconndary btn-no-border text-danger px-1"
								type="button"
								@click.prevent="closeDeleteReverseModal"
							>
								No, I don't
							</button>
							<button
								:disabled="isBusyModal"
								class="btn btn-outline-dark btn-no-border px-1"
								type="button"
								@click.prevent="reverse(itemID)"
							>
								<span v-if="isBusy">Busy...</span>
								<span v-else>Yes, I do</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import Select2 from "@/components/Select2";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import Pagination from "../components/Pagination.vue";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "ProductsBundle",
		components: {
			Select2,
			GeneralDeleteCaution,
			GeneralDeleteMessage,
			Pagination,
			vSelect,
		},

		data() {
			return {
				isBusy: false,
				isBusyModal: false,
				returnCount: 0,
				action: "",
				itemID:"",
				count: 0,
				page: 0,
				pageNumber: 0,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				isGlobal: false,
				endDate: "",
				startDate: "",
				returnDetailsiisActive: false,
				customerReturnList: [],
				MerchantFulfilledOrdersList: [],
				returnBucket: {
					customerId: "",
					returned: "",
					details: [],
				},
				customers: null,
				customersOptions: [],
				orderList: [],
				ProductList: null,
				productDetails: [],
				productDetailsItems: {},
				Locations: [],
				SubLocations: [],
				SubLocationsForUpdate: [],
				orderListNumber: "",
				returns: {
					orderListNumber: "",
					product: "",
					productQuantity: "",
					desiredQuantity: "",
					action: 0,
					locationId: "",
					subLocationId: "",
					reasons: [],
					count: 0,
					page: 0,
					pageNumber: 0,
					pageSize: 10,
					pageCount: 0,
					hasNext: false,
					hasPrev: false,
					isGlobal: false,
				},
				editReturns: {
					orderListNumber: null,
					product: null,
					productQuantity: null,
					desiredQuantity: null,
					action: null,
					locationId: null,
					subLocationId: null,
					reasons: null,
				},
				reasonList: [
					{
						label: "Damaged",
						id: "Damaged",
					},
					{
						label: "Wrong Product",
						id: "Wrong Product",
					},
					{
						label: "Expired",
						id: "Expired",
					},
					{
						label: "Wrong Quantity",
						id: "Wrong Quantity",
					},
					{
						label: "Others",
						id: "Others",
					},
				],
				actionsList: [
					{
						label: "Disposed",
						id: 0,
					},
					{
						label: "Received",
						id: 1,
					},
				],
				order: "",
				orderID: "",
				product: "",
				productQuantity: "",
				customerID: "",
				orderNumber: "",
				date: "",
				productName: "",
				seletedCustomer: "",
				maxDate: "",
				v1: {
					seletedCustomer: false,
				},
				filter: {
					status: "0",
					customerType: "0",
					qry: "",
					sortColumn: "name",
					sortDirection: "asc",
					showForMe: false,
				},

				v1: {
					orderListNumber: false,
					product: false,
					desiredQuantity: false,
					action: false,
					locationId: false,
					subLocationId: false,
				},

				bucketEdiitMode: false,
				bucketEditDataID: "",
				completeReturnsMessage: "",
				returnDetails: false,
				returnListDetails: [],
				returnItemDetails: [],
				returnID: null,
				returnDetailsID: null,
			};
		},

		created() {
			this.getCustomers();
			this.getCustomerReturns();
			this.getLocations();
			this.getSubLocations();
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},
		mounted() {
			this.maxDate = helper.preventFutureDateSelection();
		},

		methods: {
			cancel() {
				$("#mod-edit").modal("hide");
			},
			initializeCustomerReturns() {
				$("#mod-add-customer-returns").modal("show");
			},
			isSelectReasons(e) {
				this.returns.reasons = e;
			},

			back() {
				(this.returnDetails = false), (this.returnListDetails = []);
			},

			async viewDetails(id, returnID) {
				this.returnDetailsID = id;
				this.isBusy = true;
				await this.$http
					.get(`/OrderReturn/${id}/Details`)
					.then(function(res) {
						this.returnListDetails = res.body.map(x => x);
						this.returnID = returnID;
						this.returnDetails = true;
						this.isBusy = false;
					})
					.catch(function() {
						this.isBusy = false;
					});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			ReverseModal(id, action)
			{
					this.action = action
					$("#mod-action-res").modal("show");
					this.itemID = id
			},

			closeReverseModal(){
				$("#mod-action-res").modal("hide");
				this.itemID = ''
				this.action = ""
			},

			async reverse(detailID) {
				console.log(this.returnDetailsID, detailID);
				this.isBusy = true;
				await this.$http
					.put(`/OrderReturn/${this.returnDetailsID}/Reverse/${detailID}`)
					.then(function(res) {
						if (res.ok) {
							this.viewDetails(this.returnDetailsID, this.returnID);
							this.closeReverseModal()
							return;
						}
						this.isBusy = false;
						
					})
					.catch(function() {
						this.isBusy = false;
						this.closeReverseModal()
					});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			async getCustomerReturns() {
				this.isBusy = true;
				await this.$http
					.get(
						`/OrderReturn?pageNumber= ${this.pageNumber}&startDate=${
							this.startDate
						}&endDate=${this.endDate}&pageSize=${this.pageSize}&isGlobal=${!this
							.isGlobal}`,
					)

					.then(function(res) {
						this.customerReturnList = res.body.data;
						this.pageNumber = res.body.pageNumber;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			updateAddTobucket() {
				if (this.bucketEdiitMode) {
					this.updateReturnsbucketData();
					return;
				} else {
					this.updateReturnsbucket();
				}
			},

			updateReturnsbucket() {
				let returnTableList;
				if (this.validateInput()) {
					let data = {
						orderDetailId: this.orderID,
						quantity: this.returns.desiredQuantity,
						action: parseInt(this.returns.action),
						reason: this.returns.reasons.toString(),
						subLocationId: this.returns.subLocationId,
					};
					this.returnBucket.details.push(data);
					this.returns.reasons = "";
					if (this.returnBucket.customerId == "") {
						this.returnBucket.customerId = this.customerID;
					}

					let UnitPrice = this.productDetails
						.filter(x => x.id == this.orderID)
						.map(x => x.unitPrice)[0];
					let QuntityReturned = this.returns.desiredQuantity;

					returnTableList = {
						productName: this.productDetails
							.filter(x => x.id == this.orderID)
							.map(x => x.description)[0],
						productCode: this.productDetails
							.filter(x => x.id == this.orderID)
							.map(x => x.code)[0],
						productUnitPrice: this.productDetails
							.filter(x => x.id == this.orderID)
							.map(x => x.unitPrice)[0],
						productQuntityReturned: this.returns.desiredQuantity,
						productAmount: UnitPrice * QuntityReturned,
						productDiscount: this.productDetails
							.filter(x => x.id == this.orderID)
							.map(x => x.discount)[0],
						productOrderNumber: this.returns.orderListNumber,
						productAction: this.actionsList
							.filter(x => x.id == this.returns.action)
							.map(x => x.label)[0],
						productLocation: this.SubLocations.filter(
							x => x.id == this.returns.subLocationId,
						).map(x => x.name)[0],
						productwarehouseLocation: this.Locations.filter(
							x => x.id == this.returns.locationId,
						).map(x => x.name)[0],
					};

					this.returnBucket.returned = new Date().toISOString();
					this.returnItemDetails.push(returnTableList);
					this.returnItemDetails.forEach((o, i) => (o.id = i + 1));
					$("#modi-add-customer-returns-items").modal("hide");
					$("#mod-add-customer-returns-items").modal("show");
					this.addReturnsTobucket();
				}
			},

			addReturnsTobucket() {
				$("#mod-add-customer-returns-items").modal("hide");
				this.returnDetailsiisActive = true;
			},
			dontAddReturnsTobucket() {
				$("#modi-add-customer-returns-items").modal("hide");
				this.cancelCustomerReturns();
			},

			updateReturnsbucketData() {
				if (this.bucketEditDataID) {
					let objIndex = this.returnItemDetails.findIndex(
						x => x.id == this.bucketEditDataID,
					);
					let UnitPrice = this.productDetails
						.filter(x => x.id == this.orderID)
						.map(x => x.unitPrice)[0];
					let QuntityReturned = this.returns.desiredQuantity;
					(this.returnItemDetails[objIndex].productName = this.productDetails
						.filter(x => x.id == this.orderID)
						.map(x => x.description)[0]),
						(this.returnItemDetails[objIndex].productCode = this.productDetails
							.filter(x => x.id == this.orderID)
							.map(x => x.code)[0]),
						(this.returnItemDetails[
							objIndex
						].productUnitPrice = this.productDetails
							.filter(x => x.id == this.orderID)
							.map(x => x.unitPrice)[0]),
						(this.returnItemDetails[
							objIndex
						].productQuntityReturned = this.returns.desiredQuantity),
						(this.returnItemDetails[objIndex].productAmount =
							UnitPrice * QuntityReturned),
						(this.returnItemDetails[
							objIndex
						].productDiscount = this.productDetails
							.filter(x => x.id == this.orderID)
							.map(x => x.discount)[0]),
						(this.returnItemDetails[
							objIndex
						].productOrderNumber = this.returns.orderListNumber);
					(this.returnItemDetails[objIndex].productAction = this.actionsList
						.filter(x => x.id == this.returns.action)
						.map(x => x.label)[0]),
						(this.returnItemDetails[
							objIndex
						].productLocation = this.SubLocations.filter(
							x => x.id == this.returns.subLocationId,
						).map(x => x.name)[0]);
					$("#modi-add-customer-returns-items").modal("hide");
					return;
				}
			},

			editReturnsData(id) {
				this.bucketEdiitMode = !this.bucketEdiitMode;
				let editData = this.returnItemDetails.filter(x => x.productOrderNumber == id);
				this.viewRecord(editData);
			},
			deleteReturns(id) {
				let objIndex = this.returnItemDetails.findIndex(x => x.id == id);
				this.returnItemDetails.splice(objIndex, 1);
				let objIndex2 = this.returnBucket.details.findIndex(x => x.id == id);
				this.returnBucket.details.splice(objIndex2, 1);
				console.log(this.returnBucket.details);
			},

			returnAnotherProductFromThisOrder(x) {
				this.returns.orderListNumber = x;
				this.returns.product = null;
				this.returns.productQuantity = null;
				this.returns.desiredQuantity = null;
				this.returns.action = "";
				this.returns.locationId = null;
				this.returns.subLocationId = null;
				this.returns.reasons = null;
				$("#modi-add-customer-returns-items").modal("show");
			},

			async completeReturns() {
				this.isBusy = true;
				let data = this.returnBucket;
				await this.$http
					.post("/OrderReturn", data)
					.then(function(res) {
						this.$toast.success(res.body, {
							icon: false,
							rtl: false,
						});
						this.isBusy = false;
						this.returnDetailsiisActive = false;
						this.cancelCustomerReturns();
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},
			cancelCustomerReturns() {
				this.returnItemDetails = [];
				this.customerID = "";
				this.returnBucket.customerId = "";
				this.returnBucket.returned = "";
				this.returnBucket.details = [];
				this.returnDetailsiisActive = false;
				this.returns = {
					orderListNumber: "",
					product: "",
					desiredQuantity: "",
					action: "",
					locationId: "",
					subLocationId: "",
					reasons: "",
				};
				this.orderID = "";
				this.getCustomers();
				this.getCustomerReturns();
			},

			viewRecord(val) {
				this.bucketEditDataID = val[0].id;
				this.returns.orderListNumber = val[0].productOrderNumber;
				this.returns.product = this.productDetails
					.filter(x => x.description == val[0].productName)
					.map(x => x.id)[0];
				this.returns.desiredQuantity = val[0].productQuntityReturned;
				this.returns.action = this.actionsList
					.filter(x => x.label == val[0].productAction)
					.map(x => x.id)[0];
				this.returns.subLocationId = this.SubLocations.filter(
					x => x.name == val[0].productLocation,
				).map(x => x.id)[0];
				this.returns.LocationId = this.Locations.filter(
					x => x.name == val[0].productwarehouseLocation,
				).map(x => x.id)[0];
				$("#modi-add-customer-returns-items").modal("show");
			},

			clearInput() {
				this.returns = {
					orderListNumber: "",
					product: "",
					desiredQuantity: "",
					action: "",
					locationId: "",
					subLocationId: "",
					reasons: [],
				};
				this.orderID = "";
				this.cancelCustomerReturns();
				$("#modi-add-customer-returns-items").modal("show");
			},

			async getMerchantFulfilledOrders() {
				this.isBusy = true;
				const body = {
					customer: this.customerID,
				};
				await this.$http
					.get(
						`/Orders/getMerchantFulfilledOrders/${this.customerID}?OrderNumber=${this.orderNumber}&Query=${this.productName}&Created=${this.date}`,
					)
					.then(function(res) {
						this.orderList = res.body;
						$("#mod-add-customer-returns-search").modal("hide");
						$("#modi-add-customer-returns-items").modal("show");
						this.isBusy = false;
					})
					.catch(function() {});
			},

			validateInput() {
				this.v1 = {
					orderListNumber: false,
					product: false,
					desiredQuantity: false,
					action: false,
					locationId: false,
					subLocationId: false,
				};
				var isValid = true;

				if (!this.returns.orderListNumber) {
					this.v1.orderListNumber = true;
					isValid = false;
				}

				if (!this.returns.product) {
					this.v1.product = true;
					isValid = false;
				}
				if (!this.returns.desiredQuantity) {
					this.v1.desiredQuantity = true;
					isValid = false;
				}
				if (!this.returns.locationId) {
					this.v1.locationId = true;
					isValid = false;
				}
				if (!this.returns.subLocationId) {
					this.v1.subLocationId = true;
					isValid = false;
				}

				return isValid;
			},

			getProductList(val) {
				this.productList = this.orderList.filter(order => order.orderNumber == val);
				this.productDetails = this.productList[0].details;
			},
			getProductDetailsList(value) {
				this.orderID = value;
				this.productDetailsItems = this.productDetails.filter(
					product => product.id == value,
				);
				this.returns.productQuantity = this.productDetailsItems[0].quantity;
			},
			initializeCustomerReturnsSearch() {
				$("#mod-add-customer-returns").modal("hide");
				$("#mod-add-customer-returns-search").modal("show");
			},
			getLocations() {
				var t = "Location";
				var a = true;

				this.$http
					.get(
						"/Locations/getListNoPaging?active=" +
							a +
							"&parentId=" +
							this.user.parentId,
					)
					.then(function(res) {
						this.Locations = res.body;
					})
					.catch(function() {});
			},
			getSubLocations() {
				var t = "Location";
				var a = true;

				this.$http
					.get(
						"/SubLocations/getListNoPaging?active=" +
							a +
							"&parentId=" +
							this.user.parentId +
							"&locationId=",
					)
					.then(function(res) {
						this.SubLocations = res.body;
						this.SubLocationsForUpdate = res.body;
					})
					.catch(function() {});
			},
			initializeCustomerReturnsItems() {
				$("#mod-add-customer-returns-items").modal("show");
			},
			CustomerReturnssuccess() {
				$("#mod-successfull-returns").modal("show");
			},
			viewReturns(data) {
				// console.log(data);
			},
			viewFilter(data) {
				// console.log(data);
			},
			fastreturns(data) {
				// console.log(data);
			},

			// confirmDelete(id) {
			// 	this.DeleteId = id;
			// 	$("#mod-confirm-delete").modal("show");
			// },

			// showDeleteMsg() {
			// 	$("#mod-delete-msg").modal("show");
			// },

			// cancelConfirm() {
			// 	this.DeleteId = "";
			// 	$("#mod-confirm-delete").modal("hide");
			// },
			async getCustomers() {
				this.isBusy = true;
				await this.$http
					.get(`/Users/getItemListOfCustomers/${this.user.parentId}`)
					.then(function(res) {
						this.customersOptions = res.body.map(customer => ({
							key: customer.id,
							value: customer.text,
						}));
						this.isBusy = false;
					})
					.catch(function() {
						this.isBusy = false;
					});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			gotoPage(page) {
				this.pageNumber = page;
				this.getCustomerReturns();
			},
			changeLimit(limit) {
				this.pageSize = limit;
				this.getCustomerReturns();
			},
			prev() {
				this.pageNumber -= 1;
				this.getCustomerReturns();
			},
			next() {
				this.pageNumber += 1;
				this.getCustomerReturns();
			},
			formatDate(date) {
				return moment(date).format("lll");
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			formatNumber(val) {
				return helper.formatNumber(val);
			},
		},
	};
</script>

<style scoped>
	.lg-modal {
		max-width: 600px !important;
		width: 100%;
	}
	.sm-modal {
		max-width: 350px !important;
		width: 100%;
	}
	.btn-no-border {
		border: none !important;
	}
	.font-bold {
		font-weight: 500;
		color: black;
	}
	.error-text {
		font-size: 8px;
	}
</style>
