<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button class="btn btn-secondary btn-rounded btn-space" @click="viewFilter">
						<span class="feather icon-filter text-white"></span> Filters
					</button>

					<!-- <button class="btn btn-secondary btn-rounded btn-space float-right" @click="viewExport">
                      <span class="icon icon-left mdi mdi-arrow-top-right text-primary"></span> Export
                    </button>-->
				</div>
				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table class="table table-fw-widget dataTable no-footer">
									<thead>
										<tr>
											<th>Name</th>
											<th>Code</th>
											<th>Source ID</th>
											<th>Created</th>
											<th>State</th>
											<th>Group</th>
											<th>Phone</th>
											<th>Email Address</th>
											<th>Registration Status</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="m in distributors" :key="m.id">
											<td>
												<div class="d-flex align-items-center">
													<img
														v-if="m.logoUrl"
														class="image-preview mr-1"
														:src="m.logoUrl"
														alt="logo"
														width="40"
														height="40"
													/>
													<img
														v-else
														class="image-preview mr-1"
														src="/assets/img/avatar.svg"
														alt="logo"
														width="40"
													/>
													<div>
														<a href="#" @click.prevent="details(m.id)">
															<strong>{{ m.name }}</strong>
														</a>
													</div>
												</div>
											</td>
											<td>{{ m.code }}</td>
											<td>{{ m.sourceId }}</td>
											<td>{{ formatDate(m.created) }}</td>
											<td>{{ m.state }}</td>
											<td>{{ m.group }}</td>
											<td>{{ m.phone }}</td>
											<td>{{ m.emailAddress }}</td>
											<td>
												<span
													:class="[
														'badge',
														' badge-pill',
														{ 'badge-primary': m.status == 'Inactive' },
														{ 'badge-success': m.status == 'Active' },
													]"
												>
													{{ m.status }}
												</span>
											</td>
											<td>
												<div
													class="dropdown"
													v-if="user.role.isDistributorManager"
												>
													<button
														class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
														type="button"
														id="dropdownMenu2"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
													>
														Actions
													</button>
													<div
														class="dropdown-menu px-1"
														aria-labelledby="dropdownMenu2"
													>
														<a
															v-if="m.status != 'Inactive'"
															class="dropdown-item"
															@click.prevent="viewProductList(m.id)"
														>
															View Product List
														</a>
														<div class="dropdown-divider"></div>
														<a
															class="dropdown-item"
															@click.prevent="resendInvite(m.id)"
															v-if="m.status == 'Inactive'"
														>
															Resend Invite
														</a>
													</div>
												</div>
											</td>
										</tr>
										<tr v-if="!distributors.length">
											<td colspan="10">
												<div class="text-center" style="padding-top: 25px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-truck-fast"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 50px"
												>
													You don't have any distributors.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
								<Pagination
									:limit="pageSize"
									:count="pageCount"
									:page="page"
									:itemsCount="count"
									@changePage="gotoPage"
									@changeLimit="changeLimit"
								/>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} distributor{{ count != 1 ? "s" : "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-export" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Export</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body"></div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button style="width: 100px" class="btn btn-primary" type="button">
							Export
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Filter</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.status"
								>
									<option value="0">All Distributors</option>
									<option value="True">Active</option>
									<option value="False">Disabled</option>
								</select>
							</div>
						</div>
						<!-- <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Manufacturer</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.parentId"
                >
                  <option value="0">All Manufacturers</option>
                  <option v-for="m in manufacturers" :key="m.id" :value="m.id">
                    {{ m.text }}
                  </option>
                </select>
              </div>
            </div> -->
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Other filters</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by name..."
									v-model="filter.qry"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getMerchants"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import Select2 from "@/components/Select2";
	import Pagination from "../components/Pagination.vue";

	export default {
		name: "salesuserdistributors",
		components: {
			Select2,
			Pagination,
		},
		created() {
			this.getMerchants();
			//this.getManufacturers();
		},
		watch: {
			"updatingMerchant.hasCredit": function(newVal, oldVal) {
				if (!newVal) {
					this.updatingMerchant.creditLimit = 0;
				}
			},
		},
		data() {
			return {
				filter: {
					status: "0",
					parentId: "0",
					qry: "",
				},
				manufacturers: [],
				deletingMerchant: {},
				updatingMerchant: {},
				v1: {
					creditLimit: false,
				},
				isBusy: false,
				isBusyModal: false,
				distributors: [],
				count: 0,
				page: 0,
				pageNumber: 0,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
			};
		},
		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},
		methods: {
			reset() {
				this.filter = {
					status: "0",
					parentId: "0",
					qry: "",
				};
			},
			viewExport() {
				// $("#mod-export").modal("show");
			},
			viewRemove(m) {
				this.deletingMerchant = m;
				$("#mod-delete").modal("show");
			},
			viewCreditLimit(m) {
				this.updatingMerchant = m;
				$("#mod-update").modal("show");
			},
			manageManufacturers(id) {
				this.$router.push("/distributors/" + id + "/parents");
			},
			viewFilter() {
				$("#mod-filter").modal("show");
			},
			details(id) {
				this.$router.push("/sales-user-distributors/" + id);
			},
			viewProductList(distributorId) {
				this.$router.push("/sales-user-distributor-products/" + distributorId);
			},
			updateStatus() {
				this.isBusyModal = true;
				var data = {
					id: this.deletingMerchant.id,
					active: !this.deletingMerchant.active,
				};

				$("#mod-delete").modal("hide");

				this.$http
					.put("/Accounts/updateStatus/" + data.id, data)
					.then(function(res) {
						this.isBusyModal = false;
						this.getMerchants();
					})
					.catch(function() {
						this.isBusyModal = false;
					});
			},
			updateCreditLimits() {
				this.isBusyModal = true;

				if (this.validate()) {
					var data = {
						id: this.updatingMerchant.id,
						creditLimit: this.updatingMerchant.creditLimit,
						hasCredit: this.updatingMerchant.hasCredit,
					};

					$("#mod-update").modal("hide");

					this.$http
						.put("/Accounts/updateCreditLimits/" + data.id, data)
						.then(function(res) {
							this.isBusyModal = false;
							this.getMerchants();
						})
						.catch(function() {
							this.isBusyModal = false;
						});
				} else {
					this.isBusyModal = false;
				}
			},
			validate() {
				this.v1 = {
					creditLimit: false,
				};
				var isValid = true;

				if (Number(this.updatingMerchant.creditLimit) < 0) {
					this.v1.creditLimit = true;
					isValid = false;
				}

				return isValid;
			},
			async getMerchants() {
				this.isBusy = true;

				await this.$http
					.get(
						"/SalesUsers/getDistributorList?page=" +
							this.page +
							"&pageSize=" +
							this.pageSize +
							(this.filter.status != "0" ? "&active=" + this.filter.status : "") +
							(this.filter.parentId != "0"
								? "&parentId=" + this.filter.parentId
								: "") +
							(this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
							(this.user.parentId ? "&parentId=" + this.user.parentId : "") +
							(this.user.id ? "&userId=" + this.user.id : ""),
					)
					.then(function(res) {
						this.distributors = res.data.data;
						this.page = res.data.page;
						this.pageNumber = res.data.pageNumber;
						this.pageSize = res.data.pageSize;
						this.count = res.data.count;
						this.pageCount = Math.ceil(res.data.count / res.data.pageSize);
						this.hasPrev = res.data.hasPrev;
						this.hasNext = res.data.hasNext;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},
			async resendInvite(id) {
				this.isBusy = true;
				this.sendingInvite = true;

				await this.$http
					.post("/Accounts/resendInvite/" + id)
					.then(function(res) {
						this.isBusy = false;
						this.sendingInvite = false;
						if (res.ok) {
							this.$toast.success(res.body, {
								icon: false,
								rtl: false,
							});
						}
					})
					.catch(function() {
						this.isBusy = false;
						this.sendingInvite = false;
					});
			},
			formatDate(date) {
				return moment(date).format("lll");
			},

			async getManufacturers() {
				await this.$http
					.get("/Accounts/getItemList?accountType=Manufacturer")
					.then(function(res) {
						this.manufacturers = res.body;
					})
					.catch(function() {});
			},
			prev() {
				this.pageNumber -= 1;
				this.getMerchants();
			},
			next() {
				this.pageNumber += 1;
				this.getMerchants();
			},

			gotoPage(page) {
				this.pageNumber = page;
				this.getMerchants();
			},

			changeLimit(limit) {
				this.pageSize = limit;
				this.getMerchants();
			},
		},
	};
</script>
